import React from 'react'
import {CONSUMER_HOTLINE, SALE_HOTLINE} from "@utils/constant"
import PropTypes from 'prop-types'
import Animation from './animation'
import ProductApply from './productApply'
import './Footer.scss'


const contactUsContent = [
  { label: '公司地址：', value: '浙江省杭州市余杭区西溪八方城8号楼6层' },
  { label: '售后服务：', value: CONSUMER_HOTLINE },
  { label: '企业邮箱：', value: 'business@ebei99.com' },
  { label: '售前咨询：', value: SALE_HOTLINE },
]

const applyTitle = {
  '/crm': { key: '12', tab: '1', text: '赋能药械企业数字化，让管理更高效' },
  '/scm': { key: '13', tab: '1', text: '营销自动化，实现高效转化' },
  '/erp': { key: '14', tab: '1', text: '聚合医疗创新，赋能提质增效' },
  '/rfid': { key: '16', tab: '1', text: '全程溯源，避免资产浪费' },
  '/spd': { key: '31', tab: '2', text: '开启智能高效的物流管理新模式' },
  '/msc': { key: '32', tab: '2', text: '共建紧密型智慧医共体' },
  '/materials': { key: '33', tab: '2', text: '开启医疗物资管理创新之旅' },
  '/scc': { key: '34', tab: '2', text: '运营数据可视化，供应商管理更简单' },
}

export default class AppFooter extends React.PureComponent {
  static props = {
    onDidMount: PropTypes.func,
  }

  domheight = 0

  saveRef = (ref) => {
    this.domHeight = (ref && ref.offsetHeight) || 0
    const { onDidMount } = this.props
    onDidMount && onDidMount({ subViewName: 'appFooter', offset: { height: this.domHeight } })
  }

  render() {
    const {
      history,
      location: { pathname },
    } = this.props.appProps

    const applyItem = applyTitle[pathname]
    return (
      <footer className='footer' id='J-g-appFooter' ref={this.saveRef}>
        {applyItem ? <ProductApply applyItem={applyItem} /> : <Animation history={history} />}
        <div className='footer-wrap'>
          <div className='middle-position'>
            <div className='contact-left'>
              <p className='font-16'>联系我们</p>
              <div className='contact-item'>
                {contactUsContent.map((item) => (
                  <div key={item.label}>
                    <span>{item.label}</span>
                    <span>{item.value}</span>
                  </div>
                ))}
              </div>
            </div>
            {/*<div className='vertical-line'/>*/}
            <div className='contact-right'>
              <div className='img-wrap'>
                <div className='img-con'>
                  <img src='//img.ebei99.com/official-website/ebei99.logo.jpg' alt='qrcode' />
                </div>
                <div className='img-con-tips'>关注公众号</div>
              </div>
            </div>
          </div>
        </div>
        <div className='copyright-box'>
          <div className='copyright layout-width'>
            Copyright © 2019-2020 医贝云服(杭州)科技有限公司
            <span className='marin-0-10'>|</span>
            <span>
              <img src='//img.ebei99.com/common/ghs.png' alt='浙公网安备' style={{ height: '0.18rem', verticalAlign: 'middle', marginRight: '0.05rem' }} />
              <a href='http://www.beian.gov.cn/' target='_blank'>
                浙公网安备 33010502005964号
              </a>
            </span>
            <span className='marin-0-10'>|</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">
              浙ICP备19012155号-1
            </a>
            {/*<span className='marin-0-10'>|</span>*/}
            {/*互联网药品信息服务资格证书 (浙)-经营性-2020-0029*/}
          </div>
        </div>
      </footer>
    )
  }
}
