const MSPP_API_PREFIX = '/aek-mspp'

/** 邮箱校验 */
const REGEXP_EMAIL = '^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$'

/** 手机校验 */
const REGEXP_TELEPHONE = '^1[3|4|5|6|7|8|9][0-9]{9}$'

/** 各系统版本 */
const SYSTEM_CATEGORY_ENUM = {
  1: '医贝大厅',
  2: '基础数据维护',
  11: '接单平台',
  12: '医贝CRM',
  13: '医贝数字化营销',
  14: '医贝进销存',
  15: '费控报销系统',
  16: '高值耗材追溯',
  31: 'SPD物流管理系统',
  32: '医共体平台',
  33: '物资精细化系统',
  34: '供应链协调平台',
}

const IMG_ORIGINAL = 'https://img.ebei99.com/official-website'

/** 客户热线 */
const CONSUMER_HOTLINE = '400-052-5256'

const SALE_HOTLINE = '19106561680'

export { MSPP_API_PREFIX, REGEXP_EMAIL, REGEXP_TELEPHONE, IMG_ORIGINAL, CONSUMER_HOTLINE, SALE_HOTLINE, SYSTEM_CATEGORY_ENUM }
